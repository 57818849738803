// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {    this.sortable = Sortable.create(this.element, {      group: 'shared',      animation: 300,      onEnd: this.end.bind(this)    })  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("from", event.oldIndex);
    data.append("to", event.newIndex);
    data.append("world_link", this.data.get("url").split("worlds/")[1].split("/move")[0]);
    console.log(data)
    Rails.ajax({url: this.data.get("url"), type: 'PATCH', data: data})
  }
}
